import { Link } from "gatsby"
import React from "react"
import Layout from "../components/common/layout"
import Main from "../components/common/main"
import SEO from "../components/common/seo"


const intro = (
  <>
  <div className="container line-container d-flex">
    <div className="col px-0">
      <div className="row">
        <div className="col-lg-8">
          <h1 className="display-3  text-white">
            Level up your business
            <span>With the right technology partner</span>
          </h1>
          <p className="lead  text-white">
            We are a software development company crafting digital products of tomorrow
            by combining strategy, technology, and creative design.
          </p>
          <div className="btn-wrapper">
            <Link
              to="/company"
              className="btn btn-success btn-icon mb-3 mb-sm-0"
            >
              <span className="btn-inner--icon">
                <i className="fa fa-code" />
              </span>
              <span className="btn-inner--text">Learn more</span>
            </Link>
            <Link
              to="/contact"
              className="btn btn-white btn-icon mb-3 mb-sm-0"
            >
              <span className="btn-inner--icon">
                <i className="ni ni-cloud-download-95" />
              </span>
              <span className="btn-inner--text">Get a quote</span>
            </Link>
          </div>
        </div>
      </div>
    </div>
    </div>
    </>
  );

const servicesIntro = (
  <>
  <section className="section section-lg pt-lg-0 mt--200">
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-lg-12">
            <div className="row row-grid">
              <div className="col-lg-4">
                <div className="card card-lift--hover shadow border-0">
                  <div className="card-body py-5">
                    <div className="icon icon-shape icon-shape-primary rounded-circle mb-4">
                      <i className="ni ni-settings"></i>
                    </div>
                    <h6 className="text-primary text-uppercase">Development</h6>
                    <p className="description mt-3">Rise up with the right choice of technologies and process for your digital products.</p>
                    <div>
                      <span className="badge badge-pill badge-primary">Web</span>
                      <span className="badge badge-pill badge-primary">Mobile</span>
                      <span className="badge badge-pill badge-primary">Cloud</span>
                    </div>
                    <Link to="/services/development" className="btn btn-primary mt-4">Learn more</Link>
                  </div>
                </div>
              </div>
              <div className="col-lg-4">
                <div className="card card-lift--hover shadow border-0">
                  <div className="card-body py-5">
                    <div className="icon icon-shape icon-shape-success rounded-circle mb-4">
                      <i className="ni ni-ruler-pencil"></i>
                    </div>
                    <h6 className="text-success text-uppercase">Consulting</h6>
                    <p className="description mt-3">Super charge your team as we believe any product is only as good as the team and process behind it.</p>
                    <div>
                      <span className="badge badge-pill badge-success">Re-engineering</span>
                      <span className="badge badge-pill badge-success">DevOps</span>
                      <span className="badge badge-pill badge-success">Agile</span>
                    </div>
                    <Link to="/services/consulting" className="btn btn-success mt-4">Learn more</Link>
                  </div>
                </div>
              </div>
              <div className="col-lg-4">
                <div className="card card-lift--hover shadow border-0">
                  <div className="card-body py-5">
                    <div className="icon icon-shape icon-shape-warning rounded-circle mb-4">
                      <i className="ni ni-html5"></i>
                    </div>
                    <h6 className="text-warning text-uppercase">Training</h6>
                    <p className="description mt-3">Comprehensive training with real world examples to help your team hit the ground running.</p>
                    <div>
                      <span className="badge badge-pill badge-warning">Angular</span>
                      <span className="badge badge-pill badge-warning">React</span>
                      <span className="badge badge-pill badge-warning">Node.js</span>
                    </div>
                    <Link to="/services/training" className="btn btn-warning mt-4">Learn more</Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  </>
);

const IndexPage = () => (
  <Layout>
    <SEO title="Polyglots" keywords={[`polyglots`, `angular`, `react`, 'node', 'software development', 'consulting']} />
    <Main lineSkew={true} lineColor="line-default">
      {intro}
    </Main>
    { servicesIntro }
  </Layout>
)

export default IndexPage
